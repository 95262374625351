.header-desktop-only {
	width: 100%;
	margin: 0 auto;
}

.upper-header-container {
	background: linear-gradient(
		to right,
		#e6e5e6,
		#f1f0f1,
		#fdfdfd,
		#eaeaea,
		#d1d1d1
	);
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 30px;
	font-size: 13px;
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.upper-header-container p {
	font-size: 13px;
}

.upper-header-links-container a {
	color: #000;
	text-transform: uppercase;
	padding: 0 10px;
	text-decoration: none;
}

.lower-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	background: #fff;
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.lower-header-logo-container img {
	width: 250px;
	height: auto;
}

.lower-header-infos-container {
	display: flex;
	justify-content: space-between;
}

.lower-header-logo-info {
	font-size: 16px;
	margin-left: 15px;
	display: flex;
}

.lower-header-logo-info img {
	width: 50px;
	height: 40px;
}

.lower-header-logo-info-text-container {
	display: flex;
	flex-direction: column;
	margin: 0 10px;
}

.lower-header-logo-info-text-container a {
	text-decoration: none;
	color: inherit;
	width: 100%;
}

.lower-header-logo-info-title {
	font-family: 'Montserrat', sans-serif;
	font-weight: bold;
	color: #1471b3;
	margin-bottom: 5px;
	width: 100%;
}

.lower-header-logo-info-text {
	color: #a2a2a2;
	width: 100%;
}

/* NAVIGATION */

.header-navigation-container {
	display: flex;
	height: 40px;
	color: #fff;
	width: 100%;
	background: #28559e;
	justify-content: space-between;
	padding-right: 0;
}

.header-navigation-inside {
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
}

.header-navigation-links {
	display: flex;
	text-transform: uppercase;
	align-items: center;
}

.header-navigation-links a {
	color: #fff;
	text-decoration: none;
	padding: 0 15px;
	font-size: 14px;
}

.header-navigation-links a:first-child {
	padding-left: 0 !important;
}

.header-facebook-container {
	display: flex;
	width: 450px;
	height: 0;
	border-left: 40px solid transparent;
	border-bottom: 40px solid #28559e;
	justify-content: flex-end;
}

.header-facebook-container a {
	margin: 5px 0px 5px 0;
}

.header-facebook-container img {
	width: 30px;
	height: 30px;
}

@media (max-width: 990px) {
	.upper-header-container {
		display: none;
	}
	.header-navigation-links a {
		padding: 0 7px;
	}
	.lower-header-container {
		flex-direction: column;
		height: auto;
		margin-bottom: 15px;
	}

	.lower-header-logo-container {
		margin: 20px auto;
	}
}

@media (max-width: 790px) {
	.lower-header-logo-info {
		display: none;
	}
	.lower-header-container {
		margin-bottom: 0px;
	}

	.lower-header-container .desktop-only {
		display: none;
	}

	.header-navigation-container {
		display: none;
	}

	.header-desktop-only {
		display: none;
	}
}

/* Mobile Header */

.header-mobile-only {
	background-color: #28559e;
}

.mobile-header-icon {
	position: absolute;
	display: none;
	top: 10px;
	left: 10px;
	z-index: 9999;
}

.header-image-container {
	padding: 0 30px;
}

.header-mobile-container {
	display: none;
}

.header-mobile-logo {
	width: 150px;
	height: auto;
	margin: 10px;
}

.header-mobile-hamburger-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
}

.header-mobile-hamburger {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 50px;
	height: 50px;
	position: relative;
}

.header-mobile-hamburger-line {
	height: 1px;
	width: 20px;
	background-color: #fff;
	border-radius: 2px;
	display: block;
	transition: 0.5s;
	margin-right: 20px;
	transform-origin: center;
	position: absolute;
}

.header-mobile-hamburger-line:nth-child(1) {
	top: 20px;
}
.header-mobile-hamburger-line:nth-child(2) {
	top: 26px;
}
.header-mobile-hamburger-line:nth-child(3) {
	top: 32px;
}

.header-mobile-hamburger.checked .header-mobile-hamburger-line:nth-child(1) {
	transform: translateY(6px) rotate(-45deg);
	background-color: #fff;
}

.header-mobile-hamburger.checked .header-mobile-hamburger-line:nth-child(2) {
	opacity: 0;
}

.header-mobile-hamburger.checked .header-mobile-hamburger-line:nth-child(3) {
	transform: translateY(-6px) rotate(45deg);
	background-color: #fff;
}

.header.checked {
	background-color: #0f2846;
	transition: all 1s ease-in-out;
}

.mobile-menu-content-container {
	background-color: #0f2846;
	color: #fff;
	width: 100%;
	position: absolute;
	z-index: -10000;
	margin-top: 50px;
	padding: 30px;
	transition: all 0.5s ease-in-out;
	height: 0;
	opacity: 0;
	top: -201px;
}

.mobile-menu-content-container.checked {
	height: 1500px;
	opacity: 1;
	z-index: 10000;
	top: 0;
}

.mobile-menu-content-divider {
	background-color: #fff;
	height: 1px;
	width: 60px;
	margin-top: 10px;
	margin-bottom: 20px;
}

.mobile-menu-content-item {
	line-height: 1.47;
	font-size: 20px;
}

.mobile-menu-content-item a {
	text-decoration: none;
	color: #ffffff;
	text-transform: uppercase;
}

.header-mobile-facebook-container {
	display: flex;
	width: 100%;
}

.header-mobile-facebook-container a {
	margin-top: 20px;
}

.header-mobile-facebook-container img {
	width: 30px;
	height: 30px;
}

@media (max-width: 1200px) {
	.header-link-container {
		width: 65%;
	}
	.header-facebook-container {
		margin-right: 20px;
	}
}

@media (max-width: 790px) {
	.header-mobile-only {
		display: block;
	}

	.header-mobile-container {
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
}

/* FOOTER Section */

.upper-footer-container {
	padding-top: 15px;
	background: #28559e;
	height: 140px;
	display: flex;
	align-items: center;
	color: #fff;
}

.upper-footer-container > div {
	padding: 0 40px;
}

.upper-footer-logo-container img {
	width: 280px;
	height: auto;
}

.upper-footer-info-container {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.upper-footer-info-item-title {
	text-transform: uppercase;
	font-size: 22px;
	font-weight: lighter;
}

.upper-footer-info-item-line {
	border-bottom: 2px solid #fff;
	width: 350px;
	margin-top: 5px;
}

.upper-footer-info-item-content {
	display: flex;
	margin: 10px 0;
}

.upper-footer-info-item-content-section {
	font-weight: lighter;
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}

.upper-footer-info-item-content-section.navigation-margin p {
	margin-right: 40px;
}

.upper-footer-info-item-content-section.navigation-margin a {
	margin-right: 40px;
}

.upper-footer-info-item-content-section p {
	padding: 2px 0;
	font-size: 14px;
}

.upper-footer-info-item-content-section a {
	padding: 2px 0;
	font-size: 14px;
}

.upper-footer-container a {
	color: inherit;
	text-decoration: none;
}

.lower-footer-container {
	background: #0f2846;
	height: 60px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

@media (max-width: 1300px) {
	header > div {
		padding: 0 20px;
	}
	.upper-footer-container > div {
		padding: 0 20px;
	}
	.upper-footer-info-item-line {
		width: 290px;
	}
	.upper-footer-info-item-content-section.navigation-margin p {
		margin-right: 30px;
	}
	.upper-footer-info-item-content-section.navigation-margin a {
		margin-right: 30px;
	}
}

@media (max-width: 990px) {
	.upper-footer-container {
		flex-direction: column;
		height: auto;
	}
	.upper-footer-logo-container {
		margin-bottom: 20px;
	}
}

@media (max-width: 660px) {
	.upper-footer-info-container {
		flex-direction: column;
		align-items: center;
	}

	.upper-footer-info-item {
		width: 340px;
		margin: 5px 0;
	}

	.upper-footer-info-item-line {
		width: 350px;
	}

	.upper-footer-info-item-title {
		text-align: center;
	}
	.upper-footer-info-item-content {
		justify-content: space-between;
	}
}
