/* Banner */

.home-banner-container {
	position: relative;
}

.home-banner-upper {
	width: 100%;
}

.home-banner-upper-container {
	width: 100%;
}

.home-banner-upper-image {
	width: 100%;
	position: relative;
	margin-bottom: 30px;
}

.home-banner-image-container {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.home-banner-slider-item {
	width: 100%;
	height: auto;
	max-width: 1100px;
	margin: 0 auto;
}

.home-banner-upper-image-container img {
	width: 100%;
	height: auto;
	max-width: 1100px;
	margin: 0 auto;
}

.home-banner-upper-image-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: 110%;
	width: 100%;
	z-index: 100;
	-webkit-transition: bottom 0.5s ease-in;
	-o-transition: bottom 0.5s ease-in;
	transition: bottom 0.5s ease-in;
}

.home-banner-upper-image-text.active {
	-webkit-transition: bottom 0.5s ease-in;
	-o-transition: bottom 0.5s ease-in;
	transition: bottom 0.5s ease-in;
	bottom: 40% !important;
}

.home-banner-upper-image-text p {
	font-size: 60px;
	color: #fff;
	text-shadow: 2px 9px 6px #000000;
	margin-bottom: 20px;
}

.home-banner-upper-image-text p.smaller {
	font-size: 25px;
}

.bold {
	font-weight: 700;
}

.home-banner-upper-boxes-container {
	display: flex;
	justify-content: center;
	position: absolute;
	width: 100%;
	top: 85%;
}

.home-banner-upper-box {
	display: flex;
	flex-direction: column;
	padding: 20px;
	width: 280px;
	height: 220px;
	color: #fff;
}

.home-banner-upper-box.bluest {
	background: #0f2846;
}

.home-banner-upper-box.bluer {
	background: #1e4787;
}

.home-banner-upper-box.blue {
	background: #28559e;
}

.home-banner-upper-box.lessblue {
	background: #5c85bf;
}

.home-banner-upper-box-title {
	font-size: 38px;
	font-weight: 300;
	font-family: 'Raleway';
	margin-bottom: 15px;
	text-transform: uppercase;
}

.home-banner-upper-box-description {
	font-size: 14px;
	margin-bottom: 10px;
	line-height: 1.5;
	font-weight: 300;
}

.home-banner-upper-box-description.bold {
	font-size: 16px;
	font-weight: 400;
}

.home-banner-upper-buttons {
	display: flex;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: 65%;
}

.home-banner-upper-buttons a {
	color: #fff;
	background-color: #28559e;
	width: 200px;
	height: 60px;
	text-transform: uppercase;
	text-decoration: none;
	margin: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 300;
	font-size: 20px;
	border-radius: 40px;
}

.home-banner-upper-buttons a.bluer {
	background-color: #0f2846;
}

.home-banner-upper-buttons a:hover {
	text-decoration: underline;
	cursor: pointer;
}

.home-banner-lower {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 20px;
}

.home-banner-lower-title {
	font-weight: lighter;
	font-size: 35px;
	margin: 10px 0;
}

.home-banner-lower-subtitle {
	color: #1471b3;
	text-transform: uppercase;
	font-weight: bold;
	margin: 5px 0 10px;
	font-family: 'MetaPlusNormalRoman';
	font-size: 22px;
}

.home-banner-lower-divider {
	width: 100px;
	border-bottom: solid 5px #1471b3;
	margin: 5px 0 15px;
}

.home-banner-lower-description {
	margin-bottom: 10px;
	text-align: center;
	font-style: italic;
	line-height: 1.3;
	color: #757472;
	padding: 0 130px;
	max-width: 100%;
	width: 100%;
}

.home-banner-lower-description img {
	max-width: 100%;
}

.home-banner-lower-greetings {
	margin-bottom: 40px;
	font-family: 'BiloxiScript';
	font-size: 40px;
	color: #5e5e5d;
}

@media (max-width: 1300px) {
	.home-banner-upper-image {
		margin-bottom: 30px;
	}
	.home-banner-upper-image-text {
		bottom: 110%;
	}
	.home-banner-upper-boxes-container {
		position: relative;
		margin-top: -3px;
	}
	.home-banner-upper-box {
		padding-right: 15px;
	}
	.home-banner-upper-box-description {
		font-size: 13px;
	}
	.home-banner-upper-buttons {
		top: 45%;
	}
}

@media (max-width: 990px) {
	.home-banner-upper-boxes-container {
		display: none;
	}

	.home-banner-upper-image-text p {
		font-size: 45px;
		margin-bottom: 15px;
	}

	.home-banner-upper-image-text p.smaller {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.home-banner-upper-buttons {
		top: 65%;
	}

	.home-banner-lower-title {
		text-align: center;
	}

	.home-banner-lower-description {
		padding: 0 40px;
	}

	.home-banner-lower-subtitle {
		text-align: center;
	}
}

@media (max-width: 740px) {
	.home-banner-upper-image-container img {
		min-height: 235px;
	}
	.home-banner-upper-buttons {
		top: 60%;
		flex-direction: column;
		align-items: center;
	}
	.home-banner-upper-buttons a {
		height: 30px;
		margin: 5px 0;
		font-size: 18px;
	}

	.home-banner-upper-image-text p {
		text-align: center;
		font-size: 25px;
		margin-bottom: 10px;
	}

	.home-banner-upper-image-text p.smaller {
		font-size: 18px;
		width: 80%;
		line-height: 1.2;
	}

	.home-banner-lower-greetings {
		text-align: center;
	}
	.home-banner-lower-subtitle {
		line-height: 1.2;
	}
}

@media (max-width: 500px) {
	.home-banner-upper-buttons {
		top: 63%;
	}
	.home-banner-upper-image-text {
		bottom: 110%;
	}
	.home-banner-upper-image-text.active {
		transition: bottom 0.5s ease-in;
		bottom: 30% !important;
	}
}

/* Leistungen */

.home-leistungen-container {
	position: relative;
}

.home-leistungen-title {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0f2846;
	color: #fff;
	font-weight: lighter;
	font-family: 'Raleway';
	font-size: 24px;
}

.home-leistungen-slides-container {
	width: 100%;
	display: flex;
	min-height: 503px;
}

.home-leistungen-slides-image-container {
	width: 50%;
	position: relative;
}

.home-leistungen-slides-description-container {
	width: 50%;
	background: linear-gradient(
		to right,
		#e6e5e6,
		#f1f0f1,
		#fdfdfd,
		#eaeaea,
		#d1d1d1
	);
}

.home-leistungen-slides-image-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
}

.home-leistungen-slides-image-wrapper img {
	width: 100%;
	height: 100%;
}

.home-leistungen-slides-images-buttons-container {
	position: absolute;
	width: 100%;
	max-width: 100%;
	height: 100%;
}

.home-leistungen-slides-images-buttons-flex {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	height: 100%;
}

.home-leistungen-slides-images-button {
	background: #28559e;
	padding: 10px 20px;
	width: 250px;
	height: 80px;
	color: #fff;
	z-index: 10;
	display: flex;
	align-items: center;
	margin: 3px 0;
}

.home-leistungen-slides-images-button:hover {
	background: #0f2846;
	text-decoration: underline;
	cursor: pointer;
}

.home-leistungen-slides-images-button.active {
	background: #0f2846;
	text-decoration: underline;
}

.home-leistungen-slides-description-container {
	display: flex;
	flex-direction: column;
	padding: 50px;
}

.home-leistungen-slides-description-title {
	font-weight: 400;
	font-size: 25px;
	color: #0f2846;
	margin: 10px 0 15px;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
}

.home-leistungen-keywords {
	margin-top: 10px;
}

.home-leistungen-keywords p {
	font-size: 20px;
	line-height: 1.5;
}

.home-leistungen-slides-description-subtitle {
	font-weight: 700;
	font-size: 17px;
	color: #757472;
	margin: 10px 0 10px;
}

.home-leistungen-slides-description-text {
	font-weight: 300;
	font-size: 18px;
	color: #000;
	margin: 10px 0 15px;
	line-height: 1.4;
}

.home-leistungen-slides-description-more {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
}

.home-leistungen-slides-description-more a {
	width: 220px;
	height: 45px;
	background: #28559e;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 60px;
	text-transform: uppercase;
	color: #fff;
	text-decoration: none;
}

.leistungen-mobile-only {
	display: none;
}

.leistungen-desktop-only {
	display: block;
}

@media (max-width: 740px) {
	.leistungen-mobile-only {
		display: block;
	}

	.leistungen-desktop-only {
		display: none;
	}

	.home-leistungen-slides-container {
		flex-direction: column;
	}

	.home-leistungen-slides-description-title {
		font-size: 20px;
		flex-direction: column;
	}
	.home-leistungen-slides-image-container {
		width: 100%;
	}
	.home-leistungen-slides-description-container {
		width: 100%;
		padding: 30px;
	}

	.home-leistungen-slides-image-wrapper {
		display: none;
	}

	.home-leistungen-slides-images-buttons-container {
		position: relative;
		width: 100%;
		align-items: center;
	}

	.home-leistungen-slides-images-button {
		padding: 10px 10px;
	}

	.home-leistungen-slides-images-buttons-flex {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		height: auto;
	}

	.home-leistungen-slides-images-button {
		height: 70px;
		margin: 0px;
		min-width: 48%;
		width: 48%;
		margin: 1%;
		margin-top: 2%;
	}

	.home-leistungen-slides-images-button p {
		text-align: center;
		width: 100%;
		font-size: 15px;
	}

	.home-leistungen-slides-images-button a {
		color: inherit;
		text-decoration: none;
		text-align: center;
		width: 100%;
	}

	.home-leistungen-slides-container {
		background: linear-gradient(
			to right,
			#e6e5e6,
			#f1f0f1,
			#fdfdfd,
			#eaeaea,
			#d1d1d1
		);
		border-bottom: solid 1px #aaaaaa;
	}

	.home-leistungen-slides-description-more {
		justify-content: center;
	}
}

/* Referenzen */

.home-referenzen {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 100px;
}

@media (max-width: 800px) {
	.home-referenzen {
		padding: 20px 30px;
	}
}

.home-referenzen-title {
	display: flex;
	justify-content: center;
	font-weight: lighter;
	color: #000;
	font-size: 35px;
	margin: 15px 0;
	text-align: center;
}

.home-referenzen-subtitle {
	display: flex;
	justify-content: center;
	color: #1471b3;
	text-transform: uppercase;
	font-size: 20px;
	margin-bottom: 10px;
	font-family: 'MetaPlusNormalRoman';
}

.home-referenzen-divider {
	width: 100px;
	border-bottom: solid 5px #1471b3;
	margin: 10px 0 15px;
}

.home-referenzen-description {
	text-align: center;
	display: flex;
	justify-content: center;
	padding: 0 120px;
	line-height: 1.3;
	font-style: italic;
	font-family: 'Raleway';
	color: #757472;
	font-size: 16px;
	margin-top: 5px;
	margin-bottom: 35px;
	width: 100%;
	max-width: 100%;
}

.home-referenzen-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
}

.home-referenzen-buttons a {
	color: #fff;
	background-color: #28559e;
	width: 200px;
	height: 40px;
	text-transform: uppercase;
	text-decoration: none;
	margin: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: lighter;
	font-size: 16px;
	font-family: 'MetaPlusNormalRoman';
	border-radius: 20px;
}

.home-referenzen-buttons a.leistungenBack {
	width: 200px;
	height: 30px;
}

.home-referenzen-buttons a:hover {
	background-color: #1e4787;
	text-decoration: underline;
	cursor: pointer;
}

.home-referenzen-buttons a.leistungenBack:hover {
	text-decoration: none !important;
}

.home-referenzen-slider {
	margin-top: 15px;
	padding-bottom: 40px;
	text-align: center;
	position: relative;
	width: 100%;
}

.home-referenzen-image-container {
	display: flex !important;
	width: 265px;
	height: 190px;
	justify-content: center;
	align-items: center;
}

.home-referenzen-slider-item {
	max-width: 265px;
	max-height: 190px;
}

.home-slider-arrow {
	width: 20px;
	height: auto;
}

@media (max-width: 740px) {
	.home-referenzen-description {
		padding: 0 40px;
	}
	.home-referenzen-buttons a {
		margin: 5px 0;
	}
}

@media (max-width: 500px) {
	.home-referenzen-title {
		margin: 10px 0;
	}
	.home-referenzen-description {
		padding: 0 10px;
		margin-bottom: 10px;
	}
	.home-referenzen-slider {
		padding-bottom: 10px;
	}

	.home-referenzen-buttons {
		flex-direction: column;
		margin-bottom: 10px;
	}

	.home-referenzen-buttons.leistungen {
		margin-top: 20px;
	}
}

/* Kontakt */

.home-kontakt {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	background: linear-gradient(
		to right,
		#e6e5e6,
		#f1f0f1,
		#fdfdfd,
		#eaeaea,
		#d1d1d1
	);
}

.home-kontakt-title {
	display: flex;
	justify-content: center;
	font-weight: lighter;
	color: #000;
	font-size: 35px;
	margin: 15px 0;
}

.home-kontakt-subtitle {
	display: flex;
	justify-content: center;
	color: #1471b3;
	text-transform: uppercase;
	font-size: 20px;
	margin-bottom: 10px;
	font-family: 'MetaPlusNormalRoman';
}

.home-kontakt-divider {
	width: 100px;
	border-bottom: solid 5px #1471b3;
	margin: 10px 0 15px;
}

.home-kontakt-info {
	padding: 0 20px;
	width: 100%;
	display: flex;
	margin-bottom: 20px;
	margin-top: 15px;
}

.home-kontakt-info-image {
	width: 33%;
	display: flex;
	justify-content: center;
	position: relative;
	padding-bottom: 24%;
	height: 0;
	overflow: hidden;
	margin-right: 20px;
}

.home-kontakt-info-image iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.home-kontakt-info-image.kontakt-mobile-only {
	display: none;
}

@media (max-width: 1100px) {
	.home-kontakt-info-image {
		padding-bottom: 36%;
	}
}

.home-kontakt-info-address {
	width: 33%;
	padding: 0 20px;
}

.home-kontakt-info-address-title {
	font-family: 'Montserrat';
	color: #757472;
	font-size: 22px;
	margin-bottom: 15px;
}

.home-kontakt-info-address-section {
	display: flex;
	align-items: center;
	margin: 15px 0;
	font-family: 'Raleway';
}

.home-kontakt-info-address-section p {
	font-weight: 300;
	font-size: 17px;
	margin-left: 4px;
}

.home-kontakt-info-address-section a {
	text-decoration: none;
	color: inherit;
}

.home-kontakt-info-address-section-img-container {
	width: 30px;
	height: 30px;
}

.home-kontakt-info-address-section-img-container img {
	height: 25px;
	width: auto;
}

.home-kontakt-info-inputs {
	width: 33%;
	display: flex;
	flex-direction: column;
	padding: 0 20px;
}

.home-kontakt-info-inputs input {
	height: 40px;
	width: 100%;
	padding: 5px;
	margin-bottom: 20px;
	font-size: 20px;
}

.home-kontakt-info-inputs-label-img-container {
	width: 25px;
	height: 25px;
	display: inline-block;
	margin-right: 3px;
}

.home-kontakt-info-inputs-label-img-container img {
	height: 20px;
	width: auto;
}

.home-kontakt-submit {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.home-kontakt-submit button {
	color: #fff;
	background-color: #28559e;
	width: 150px;
	height: 40px;
	text-transform: uppercase;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 300;
	font-size: 20px;
	border-radius: 40px;
	border: none;
}

.home-kontakt-requests-received {
	text-align: center;
	color: #757472;
	min-height: 380px;
	font-size: 30px;
	font-weight: 700;
	display: flex;
	padding-top: 40px;
	justify-content: center;
}

@media (max-width: 990px) {
	.home-kontakt-info-image {
		display: none;
	}

	.home-kontakt-info-image.kontakt-mobile-only {
		display: flex;
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 60%;
		margin-right: 0;
	}

	.home-kontakt-info-address {
		width: 50%;
	}

	.home-kontakt-info-inputs {
		width: 50%;
	}
}

@media (max-width: 740px) {
	.home-kontakt-info {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
	}

	.home-kontakt-subtitle {
		text-align: center;
	}

	.home-kontakt-info-address {
		margin-bottom: 25px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.home-kontakt-info-address-section {
		width: 275px;
	}

	.home-kontakt-info-inputs {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.home-kontakt-info-inputs label {
		width: 300px;
	}

	.home-kontakt-info-inputs input {
		width: 300px;
	}

	.home-kontakt-info-address-title {
		text-align: center;
	}

	.home-kontakt-submit {
		justify-content: center;
	}
}
