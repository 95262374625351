.leistungen-detail {
	padding: 40px;
}

.leistungen-images {
	display: flex;
	margin-top: 40px;
	width: 100%;
	flex-wrap: wrap;
}

.leistungen-images-children {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 2500px;
}

.leistungen-image-container {
	width: 300px;
	max-width: 300px;
	height: 180px;
	max-height: 180px;
	margin-right: 20px;
	margin-bottom: 30px;
}

.leistungen-image-container > img {
	width: 300px;
	max-width: 300px;
	height: 180px;
	max-height: 180px;
}

.leistungen-image-container > div {
	width: 300px;
	max-width: 300px;
	height: 180px;
	max-height: 180px;
}

.leistungen-image-container > div > img {
	width: 300px;
	height: 180px;
}

.leistungen-desktop-only {
	display: block;
}

.leistungen-mobile-only {
	display: none;
}

@media (max-width: 500px) {
	.leistungen-image-container {
		margin-right: 0;
	}

	.leistungen-desktop-only {
		display: none;
	}

	.leistungen-mobile-only {
		display: block;
	}
}

@media (max-width: 750px) {
	.leistungen-images-children {
		justify-content: center;
	}

	.leistungen-detail {
		padding: 40px 15px;
	}
}

@media (min-width: 1650px) {
	.leistungen-image-container {
		width: 360px;
		max-width: 360px;
		height: 240px;
		max-height: 240px;
		margin-right: 30px;
		margin-bottom: 30px;
	}

	.leistungen-image-container > div {
		width: 360px;
		max-width: 360px;
		height: 240px;
		max-height: 240px;
	}

	.leistungen-image-container > img {
		width: 360px;
		max-width: 360px;
		height: 240px;
		max-height: 240px;
	}

	.leistungen-image-container > div > img {
		width: 360px;
		height: 240px;
	}
}

@media (min-width: 2000px) {
	.leistungen-image-container {
		width: 420px;
		max-width: 420px;
		height: 280px;
		max-height: 280px;
		margin-right: 40px;
		margin-bottom: 40px;
	}

	.leistungen-image-container > div {
		width: 420px;
		max-width: 420px;
		height: 280px;
		max-height: 280px;
	}

	.leistungen-image-container > img {
		width: 420px;
		max-width: 420px;
		height: 280px;
		max-height: 280px;
	}

	.leistungen-image-container > div > img {
		width: 420px;
		height: 280px;
	}
}

@media (min-width: 2350px) {
	.leistungen-image-container {
		width: 450px;
		max-width: 450px;
		height: 300px;
		max-height: 300px;
		margin-right: 60px;
		margin-bottom: 60px;
	}

	.leistungen-image-container > div {
		width: 450px;
		max-width: 450px;
		height: 300px;
		max-height: 300px;
	}

	.leistungen-image-container > img {
		width: 450px;
		max-width: 450px;
		height: 300px;
		max-height: 300px;
	}

	.leistungen-image-container > div > img {
		width: 450px;
		height: 300px;
	}
}

.__react_modal_image__medium_img {
	max-height: 85% !important;
	max-width: 85% !important;
}
