*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	font-family: 'Raleway', sans-serif;
}

@font-face {
	font-family: 'BiloxiScript';
	src: url('/fonts/Biloxi_Script.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MetaPlusNormalRoman';
	src: url('/fonts/metaplusnormal_roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* montserrat-regular - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/montserrat-v25-latin-regular.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/montserrat-v25-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/montserrat-v25-latin-regular.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/montserrat-v25-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg');
	/* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/montserrat-v25-latin-700.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/montserrat-v25-latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/montserrat-v25-latin-700.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/montserrat-v25-latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/montserrat-v25-latin-700.svg#Montserrat') format('svg');
	/* Legacy iOS */
}

/* raleway-100 - latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 100;
	src: url('/fonts/raleway-v28-latin-100.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/raleway-v28-latin-100.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/raleway-v28-latin-100.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/raleway-v28-latin-100.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/raleway-v28-latin-100.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/raleway-v28-latin-100.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* raleway-300 - latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/raleway-v28-latin-300.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/raleway-v28-latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/raleway-v28-latin-300.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/raleway-v28-latin-300.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/raleway-v28-latin-300.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/raleway-v28-latin-regular.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/raleway-v28-latin-regular.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/raleway-v28-latin-regular.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/raleway-v28-latin-regular.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/raleway-v28-latin-regular.svg#Raleway') format('svg');
	/* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/raleway-v28-latin-700.eot');
	/* IE9 Compat Modes */
	src: local(''),
		url('/fonts/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('/fonts/raleway-v28-latin-700.woff2') format('woff2'),
		/* Super Modern Browsers */
		url('/fonts/raleway-v28-latin-700.woff') format('woff'),
		/* Modern Browsers */
		url('/fonts/raleway-v28-latin-700.ttf') format('truetype'),
		/* Safari, Android, iOS */
		url('/fonts/raleway-v28-latin-700.svg#Raleway') format('svg');
	/* Legacy iOS */
}

.slick-prev {
	left: -20px !important;
}

.slick-next {
	right: -20px !important;
}

.slick-prev,
.slick-next {
	width: auto !important;
	height: auto !important;
	z-index: 1000 !important;
}

.slick-prev:before,
.slick-next:before {
	content: '' !important;
}

b {
	font-weight: bold;
}

.cookie-banner {
	font-family: 'Source Sans Pro';
	min-height: 65px;
	background-size: 30px 30px;
	background-color: #28559e;
	font-size: 16px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	padding: 10px 20px;
}

.cookie-banner a {
	text-decoration: none;
	margin: 0 10px;
	color: #fff;
	font-weight: 700;
}

.cookie-banner button {
	border: 1px solid #fff;
	border-radius: 4px;
	width: 120px;
	height: 40px;
	background: transparent;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	opacity: 1;
	right: 20;
}