.referenzen-container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 100px;
}

.referenzen-details {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	padding: 20px;
}

.referenzen-detail-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 40px;
}

.referenzen-detail-container img {
	margin-bottom: 10px;
	width: 500px;
	height: 300px;
}

.referenzen-detail-container p {
	font-weight: 700;
	width: 500px;
	line-height: 1.2;
}

.referenzen-liste {
	width: 100%;
	margin: 20px 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.referenzen-liste-title {
	font-size: 20px;
	margin: 20px 0;
	font-weight: 700;
}

.referenzen-liste-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 50px;
}

.referenzen-liste-items-container {
	display: flex;
	width: 100%;
	font-size: 18px;
	justify-content: center;
}

.referenzen-liste-items-container p {
	padding: 10px 15px;
}

.referenzen-liste-items-container-name {
	width: 50%;
}

.referenzen-liste-items-container-type {
	width: 15%;
}

.referenzen-liste-items-container-price {
	min-width: 90px;
}

@media (max-width: 1100px) {
	.referenzen-detail-container img {
		width: 333px;
		height: 200px;
	}

	.referenzen-detail-container p {
		width: 250px;
	}
	.referenzen-liste-items-container-name {
		width: 60%;
	}

	.referenzen-liste-items-container-type {
		width: 25%;
	}
}

@media (max-width: 800px) {
	.referenzen-container {
		padding: 20px 30px;
	}
}

@media (max-width: 500px) {
	.referenzen-liste-items-container {
		font-size: 18px;
	}
	.referenzen-container {
		padding: 20px 10px;
		padding-left: 15px;
	}

	.referenzen-liste-items-container p {
		padding: 10px 5px;
		word-wrap: break-word;
	}
	.referenzen-liste-items-container-type {
		width: 22%;
	}
	.referenzen-liste-items-container-price {
		min-width: 70px;
	}
}
