.datenschutz-description {
	color: #757472;
	display: flex;
	width: 100%;
	justify-content: center;
}

.datenschutz-description-item {
	padding: 20px;
	margin: 10px 30px;
	width: 100%;
}

.datenschutz-description-item p {
	line-height: 1.2;
}

.datenschutz-description-item span {
	word-break: break-all;
}

@media (max-width: 1030px) {
	.datenschutz-description {
		flex-direction: column;
		align-items: center;
	}

	.datenschutz-description-item {
		margin: 10px 0;
		padding: 0px;
	}
}
